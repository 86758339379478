import React from "react"
import styled from "styled-components"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import WelcomeBackImg from "../../assets/images/reativar-conta.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import spacing from "../../styles/tokens/spacing"

const Container = styled.div `
  padding: 0 ${spacing.layout3xl};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;


  @media screen and (max-width: 1020px) {
    padding: 0;
    gap: 0;
    justify-content: center;
  }
`

const Content = styled.section `
  width: 100%;
  max-width: 68.75rem;
  min-height: calc(100vh - 13.25rem);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing['2xl']} 0 ${({ theme }) => theme.spacing.layout2l};
  gap: 1.375rem;

  @media screen and (max-width: 1020px) {
    padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.xl};
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`

const ContentInfos = styled.div `
  max-width: 27.8125rem;
`

const ImageWrapper = styled.div `
  position: relative;
  max-width: 29.6875rem;
  max-height: 24.5rem;
`

const Image = styled(WelcomeBackImg) `
  max-width: 29.6875rem;
  max-height: 24.5rem;
  position: relative;
`

const ImageCredit = styled.span `
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXS};
  position: absolute;
  bottom: 1.9rem;
  right: ${({ theme }) => theme.spacing.l};
`

const ImageCreditLink = styled.a `
  text-decoration: underline;
`

const Heading = styled.div `
  width: 100%;
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXL};
  font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
  margin-bottom: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
`

const Text = styled.p `
  font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
  font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};
  color: ${({ theme }) => theme.colors.gray.dark};
  margin-bottom: ${({ theme }) => theme.spacing.l};
`
export default function DesativarConta() {
  return (
    <Container>
      <Header />
      <Content>
        <ContentInfos>
          <Heading>Conta reativada com sucesso!</Heading>
          <Text>
            Boas-vindas novamente! Sua conta de profissional da Lacrei Saúde foi
            reativada.
          </Text>
          <Text>Agora você já pode receber agendamentos de consultas.</Text>
          <Button title="Voltar para o painel" link="/saude/painel-inicial"/>
        </ContentInfos>
        <ImageWrapper>
          <Image />
          <ImageCredit>
            Ilustrado por{" "}
            <ImageCreditLink target={"_blank"} href="https://br.freepik.com/">
              Freepik
            </ImageCreditLink>
          </ImageCredit>
        </ImageWrapper>
      </Content>
      <Footer />
    </Container>
  )
}
